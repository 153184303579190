import gsap from 'gsap';
import React, { useEffect, useRef, useState } from 'react';

// Import toastify css file
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ButtonBase, IconButton, Typography } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import DescriptionList from "./DescriptionList.js"

export default function Project(props) {
    const loadingText = useRef()
    const description = useRef()
    const iframe = useRef()

    const [loaded, setLoaded] = useState(false)
    const [showDescription, setShowDescription] = useState(false)
    const [interactive, setInteractive] = useState(false)

    const interactiveProp = props.interactive

    useEffect(() => {
        if (!loaded) {
            gsap.to(loadingText.current, { duration: 2, transform: "scale(1.1)", yoyo: true, repeat: -1 })
        }
        if (!interactiveProp) {
            setInteractive(false)
            hideDescription()
        }


    }, [loaded, interactiveProp])

    const displayDescription = () => {
        setShowDescription(true)
        gsap.to(description.current, { duration: 0.5, transform: "translate(0,0)" })
    }

    const hideDescription = () => {
        if (showDescription) {
            gsap.to(description.current, { duration: 0.5, transform: "translate(0,61vh)" }).then(() => setShowDescription(false))
        }
        setShowDescription(false)
    }


    return (
        <div className={props.name ? `fadeIn` : props.fadeOut ? "fadeOut" : ""} style={{ display: "flex", alignItems: "center", justifyContent: "center", opacity: props.name ? 1 : props.fadeOut ? 1 : 0.2, width: "100vw", height: "100vh", scale: props.name ? 1 : props.fadeOut ? 1 : 0.5 }}>


            <div style={{ height: "70vh", width: "70vw", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                {loaded ?
                    <Typography variant="h5" style={{ color: "rgb(180, 97, 185)", alignSelf: "start", marginLeft: "10vw", marginBottom: "5px" }}>
                        {props.title}
                    </Typography>

                    :
                    null}

                <div style={{ display: loaded ? "block" : "none" }}>
                    <ButtonBase onClick={() => setInteractive(true)}>
                        <img onLoad={() => setLoaded(true)} alt={props.overview} src={props.imgUrl} style={{ width: "60vw", height: "60vh", objectFit: "cover", borderRadius: 50, filter: showDescription ? "grayscale(80%) opacity(40%)" : "none",border:"solid 2px white" }} />
                    </ButtonBase>

                </div>

                {loaded ? null :
                    <div>

                        <div>
                            <svg version="1.1" class="spinner" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 50 50" fill="none" xmlSpace="preserve">
                                <g>
                                    <g>
                                        <path fill="none" stroke="#FFFFFF" d="M24.997,47.511C11.214,47.511,0,36.298,0,22.515C0,12.969,5.314,4.392,13.869,0.132
     c0.385-0.191,0.848-0.117,1.151,0.186s0.381,0.766,0.192,1.15C13.651,4.64,12.86,8.05,12.86,11.601
     c0,12.681,10.316,22.997,22.997,22.997c3.59,0,7.033-0.809,10.236-2.403c0.386-0.191,0.848-0.117,1.151,0.186
     c0.304,0.303,0.381,0.766,0.192,1.15C43.196,42.153,34.597,47.511,24.997,47.511z"/>
                                    </g>
                                </g>
                            </svg>
                            <Typography ref={loadingText} style={{ letterSpacing: 5, color: "white", marginTop: 150, fontSize: 10 }}>
                                Loading Interactive Demo...
                            </Typography>
                        </div>

                    </div>
                }



            </div>

            <div style={{ display: interactive ? "block" : "none", position: "absolute", zIndex: 1000000 }}>
                <div style={{ background: "black", cursor: "pointer" }} onClick={hideDescription}>
                    <iframe ref={iframe} onLoad={() => setLoaded(true)} onError={(e) => { console.log("ERROR") }} src={props.demoUrl} style={{border:"solid 2px white", pointerEvents: showDescription ? "none" : "auto", height: "80vh", width: "80vw", borderRadius: 50, filter: showDescription ? "grayscale(80%) opacity(40%)" : "none" }} />
                </div>


                <IconButton onClick={displayDescription} disableRipple style={{ position: "absolute", top: "85%", left: "93%", zIndex: 99999, color: showDescription ? "rgba(0,0,0,0)" : "rgb(111,137,230)" }}>
                    <InfoOutlinedIcon style={{ fontSize: 35 }} />
                </IconButton>
                <div style={{ position: "absolute", bottom: "0vh", height: "60vh", width: "100%", overflow: "hidden", borderBottomLeftRadius: 50, borderBottomRightRadius: 50 }}>
                    <div ref={description} style={{ transform: "translate(0,61vh)", position: "absolute", display: "flex", width: "calc(100% - 4px)", zIndex: 100000, height: "calc(60vh - 4px)", flexDirection: "column", justifyContent: "center", cursor: "default", backgroundColor: "black",  borderBottomLeftRadius: 50, borderBottomRightRadius: 50,border:"solid rgb(102,102,102) 2px",borderTop:"solid rgb(102,102,102) 1px" }}>
                        <div style={{ width: "98%", display: "flex", justifyContent: "space-between", paddingTop: 15 }}>
                            <div style={{ flexGrow: 2, display: "flex", justifyContent: "space-between",marginLeft:20,marginRight:20,gap:20 }}>
                                <div>
                                    <div style={{ display: "flex", justifyContent: "space-between",alignItems:"center" }}>
                                        <Typography variant="h6" style={{ color: "rgb(111,137,230)" }}>
                                            Overview:
                                        </Typography>
                                        {props.award ?
                                            <Typography variant="subtitle" style={{ color: "rgb(111,137,230)" }}>
                                                Award: {props.award}
                                            </Typography>
                                            : null}
                                    </div>
                                    <Typography variant="body" style={{ textAlign: "left" }}>
                                        <DescriptionList list={props.overview} />
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant="h6" style={{ color: "rgb(111,137,230)" }}>
                                        Additional Info:
                                    </Typography>
                                    <Typography variant="body" style={{ textAlign: "left" }}>
                                        <DescriptionList list={props.info} />
                                    </Typography>
                                </div>
                            </div>

                        </div>
                        <IconButton onClick={hideDescription} style={{ position: "absolute", top: 0, right: 0 }}>
                            <CloseOutlinedIcon color="primary" />
                        </IconButton>
                    </div>
                </div>
            </div>
        </div >
    )
}
