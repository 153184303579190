import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import InfoIcon from '@mui/icons-material/Info'
import { IconButton, Link, Typography, useTheme } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Expo } from 'gsap'
import { ScrollTrigger } from 'gsap/all'
import Carousel from "nuka-carousel"
import { useEffect, useRef, useState } from 'react'
import { toast } from "react-toastify"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import Project from "./Project"
import "./Projects.css"
import DescriptionList from './DescriptionList'

export default function Projects(props) {
    let carousel = useRef()
    const container = useRef()
    const [selected, setSelected] = useState(0)
    const [unselect, setUnselected] = useState(-1)
    const [slideIndex, setSlideIndex] = useState(0)

    const setProject = (progress) => {
        const interval = 1 / props.projects.length

        const nextSlideIndex = Math.floor(progress / interval)
        if (slideIndex != nextSlideIndex) {
            setSlideIndex(nextSlideIndex)
        }
    }


    const theme = useTheme()
    const showSideImg = useMediaQuery(theme.breakpoints.up('md'))


    const numProjects = props.numProjects
    useEffect(() => {
        if (numProjects != 0 && showSideImg) {
            ScrollTrigger.create({ trigger: container.current, pin: true, start: "center center", end: `+=${75 * numProjects}%`, pinSpacer: true, scrub: 1, onUpdate: ((obj) => setProject(obj.progress)) })
        }
    }, [numProjects, showSideImg])

    const ease = (idx, endIdx) => {
        setSelected(endIdx)
        setUnselected(idx)
    }


    var notified = false
    const displayNotification = () => {
        if (!notified) {
            toast.info("Unable to load interactive project demos, images loaded instead.", { theme: "dark", position: "bottom-right", progressStyle: { backgroundColor: " rgb(180,97,185)" }, icon: <InfoIcon style={{ color: "rgb(180,97,185)" }} /> });
            notified = true
        }
    };

    return (
        <div ref={container} style={{ width: `100vw`, position: "absolute", backgroundColor: "black", zIndex: 1000, perspective: "100px", marginTop: showSideImg ? "1000px" : "0px", display: "flex", justifyContent: "center", alignItems: "center" }}>


            {showSideImg ?
                <>
                    <Carousel lazyLoad={true} wrapAround={true} slideIndex={slideIndex} enableKeyboardControls={true} pauseOnHover={false} dragging={false} ref={carousel} cellAlign="center" beforeSlide={(idx, endIdx) => ease(idx, endIdx)} easing={Expo.easeOut} withoutControls={true} style={{ width: "100vw", height: "100vh", }} autoplayInterval={4000} autoplay={false} speed={2000}>
                        {props.projects.map((obj, idx) => {
                            return <Project award={obj.award} interactive={idx == slideIndex} demoUrl={obj.demoUrl} imgUrl={obj.imgUrl} overview={obj.overview} info={obj.info} title={obj.title} displayNotification={displayNotification} key={idx} name={idx === selected} fadeOut={idx === unselect} />
                        })}

                    </Carousel >
                    <IconButton onClick={() => setSlideIndex(slideIndex > 0 ? slideIndex - 1 : props.projects.length - 1)} style={{ position: "absolute", top: "50%", left: "5%" }}>
                        <ChevronLeft style={{ color: "white", fontSize: 40 }} />
                    </IconButton>
                    <IconButton onClick={() => setSlideIndex(slideIndex < props.projects.length ? slideIndex + 1 : 0)} style={{ position: "absolute", top: "50%", right: "5%" }}>
                        <ChevronRight style={{ color: "white", fontSize: 40 }} />
                    </IconButton>
                </>
                :
                <div style={{ display: "flex", flexDirection: "column", gap: 40, padding: "4%", width: "100%" }}>
                <Typography variant="h3" textAlign={"left"} style={{ color: "rgb(142, 132, 207)" }}>
                    Projects
                </Typography> 
                {props.projects.map((obj, idx) => (
                        <div key={idx} style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 10,width:"100%" }}>
                            <Link target="_blank" href={obj.demoUrl} style={{width:"100%"}}>
                                <Typography variant="h4" color="white" textAlign="left" style={{width:"100%"}}>
                                    {obj.title}
                                </Typography>
                            </Link>

                            <img src={obj.imgUrl} style={{borderRadius:10,border:"solid 1px rgb(111,137,230)", width: "100%", height: "150px", objectFit: "cover" }} />
                        </div>
                    ))}
                </div>



            }

        </div >
    )
}
