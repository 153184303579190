import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import gsap from 'gsap';
import React, { useEffect, useRef, useState } from 'react';
import "./AboutMe.css";
import javaLogo from './javaLogo.svg';
import python from './python.svg';
import useWindowDimensions from './windowDimensions';
export default function AboutMe() {
    const [selected, setSelected] = useState("java")

    const { width, height } = useWindowDimensions()


    let triangle = useRef()
    let about = useRef()
    let pythonLogoRef = useRef()
    let javaLogoRef = useRef()
    let reactLogoRef = useRef()
    let pythonText = useRef()
    let reactText = useRef()
    let container = useRef()

    var numPasses = 0;
    var numPassesReact = 0;

    const setLanguage = (progress) => {
        if (progress <= 0.09) {
            setSelected("java")
        }
        else if (progress >= 0.1753 && progress <= 0.39) {
            setSelected("python")
        }
        else if (progress >= 0.6) {
            setSelected("react")
        }
    }

    const theme = useTheme()
    const showSideImg = useMediaQuery(theme.breakpoints.up('md'))

    useEffect(() => {
        if(showSideImg){
        gsap.to(about.current, { duration: 3, opacity: 1, scrollTrigger: { trigger: container.current, start: "top +=75vh", end: "+=50px", scrub: false } })


        gsap.to(triangle.current, { rotation: 240, transformOrigin: "50% 43%", scrollTrigger: { trigger: container.current, pin: true, start: "center center", end: "+=1000", pinSpacer: true, scrub: 1, onUpdate: ((obj) => setLanguage(obj.progress)) } })
        gsap.to([pythonLogoRef.current, javaLogoRef.current, reactLogoRef.current], { rotation: -240, scrollTrigger: { trigger: container.current, pin: true, start: "center center", end: "+=1000", pinSpacer: true, scrub: 1 } })
        }
    }, [showSideImg])


    console.log(showSideImg)

    const spin = (language) => {
        if (language === "react") {
            window.scrollTo(0, height + 1000)
        }
        if (language === "python") {
            window.scrollTo(0, height + 300)
        }
        if (language === "java") {
            window.scrollTo(0, height)
        }
    }

    const languageUsage = (selected) => {
        if (selected === "python" && showSideImg) {
            numPasses += 1
            if (numPasses == 1) {
                gsap.to(pythonText.current, { opacity: 1, duration: 3 })
            }

        }
        else if(selected=="react"  && showSideImg){
            numPassesReact += 1
            if (numPassesReact == 1) {
                gsap.to(reactText.current, { opacity: 1, duration: 3 })
            }
        }
        return (
            <React.Fragment>
                <Typography variant="p" style={{ color: "white", lineSpacing: "26px", marginTop: "10px" }}>
                    I'm fluent in java and have built Spring Boot servers using the Spring framework for java. I received a 5 on the AP Computer science exam and am proficient in OOP.
                </Typography>
                
                <br />
                <br />
                <Typography ref={pythonText} variant="p" style={{ color: "white", opacity:showSideImg? (numPasses <= 1 ? 0 : 1):1, lineSpacing: "26px", marginTop: "10px" }}>
                    I enjoy doing data analysis and machine learning using python. I have experience using Scikit-Learn, Pandas, NumPy, and PyTorch, and enjoy implementing new AI concepts.
                </Typography>
                <br />
                <br />
                <Typography ref={reactText} variant="p" style={{ color: "white", opacity:showSideImg? (numPassesReact <= 1 ? 0 : 1): 1, lineSpacing: "26px", marginTop: "10px" }}>
                    I'm fluent in javascript and swift and love building responsive web apps using ReactJS and mobile apps using Swift. Check out some of my projects down below, more demos of ML projects will be added in the future!
                </Typography>
            </React.Fragment>
        )
        
    }

    return (
        <div id="aboutMe" ref={container} style={{ display: "flex", zIndex: 500, justifyContent: "space-between", color: "white", backgroundColor: "black", alignItems: "center" }} className="panel">

            <div className="about" ref={about} style={{ top: "40%", alignSelf: "center",opacity:showSideImg?0:1}}>
                <Typography variant="h3" style={{ color: "rgb(142, 132, 207)" }}>
                    About Me
                </Typography>
                <br />
                <Typography variant="p" style={{ color: "white", lineSpacing: "26px", marginTop: "10px" }}>
                    I'm senior at the Noble and Greenough school in Dedham, Massachussets. I enjoy playing tennis, reading, and coding. I'm specifically interested in both Artificial Intelligence and Neuroscience, and the intersection between the two fields.
                </Typography>
                <br />
                <br />
                {languageUsage(selected)}

            </div>
            <div className="images" style={{ position: "default", left: 0, top: "35%",zIndex:1000,display:showSideImg?"block":"none" }}>
                <div className="triangle" ref={triangle}>
                    <div className="top" >
                        <img ref={javaLogoRef} className="image topLeft" src={javaLogo} onClick={() => spin("java")} style={{ marginRight: showSideImg ? "40px" : "80px" }} />
                        <svg style={{ marginLeft: showSideImg ? "40px" : "80px" }} ref={reactLogoRef} onClick={() => spin("react")} className="image topRight" xmlns="http://www.w3.org/2000/svg" viewBox="-11.5 -10.23174 23 20.46348">
                            <circle cx="0" cy="0" r="2.05" fill="#61dafb" />
                            <g stroke="#61dafb" strokeWidth="1" fill="none">
                                <ellipse rx="11" ry="4.2" />
                                <ellipse rx="11" ry="4.2" transform="rotate(60)" />
                                <ellipse rx="11" ry="4.2" transform="rotate(120)" />
                            </g>
                        </svg>
                    </div>
                    <div ref={pythonLogoRef} className="aloneImg" onClick={() => spin("python")}>
                        <img className="image" src={python} />
                    </div>

                </div>


            </div>
        </div>
    )
}
