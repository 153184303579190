import React from 'react'
import gsap from 'gsap';
import { useRef } from 'react';
import { Tooltip } from '@mui/material';

export default function Contact(props) {
    const contact = useRef()
    let selector = gsap.utils.selector(contact)
    const animate = () => {
        gsap.to(selector(".contact"), { duration: 0.5, transform: 'scale(1.25)', backgroundColor: props.hoverColor, cursor: "pointer" })
    }

    const reset = () => {
        gsap.to(selector('.contact'), { duration: 0.5, transform: "scale(1)", backgroundColor: props.startColor, cursor: "default" })
    }
    return (
        <Tooltip title={props.tooltipTitle} onOpen={animate} onClose={reset}>
            <a target="_blank" rel="noreferrer" href={props.link} ref={contact} >
                {props.children}
            </a>
        </Tooltip>
    )
}
