import React from 'react'
import gsap from 'gsap'
import { useEffect, useRef, useState } from 'react'
import ButtonBase from '@mui/material/ButtonBase'
import "./LandingPage.css"
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';
import Start from './Start'

export default function LandingPage(props) {
    const path = useRef()
    const svgIcon = useRef()
    const start = useRef()


    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'))

    useEffect(() => {

    }, [])
    return (
        <div className="panel" style={{height:isMd?"100vh":"80vh"}} id="start">
            <Start  isLg={props.isLg}/>
        </div>
    )
}
