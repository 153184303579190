import CloseIcon from '@mui/icons-material/Close';
import { Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import './App.css';
import Contacts from './Contacts';
import gsap from 'gsap';
import { useRef } from 'react';

export default function Sidebar(props) {
    let closeIcon = useRef()

    

    const animateOut = () => {
        gsap.to(closeIcon.current,{rotate:360,duration:1,ease:"power1.inOut", transformOrigin:"50% 50%"}).then(() => {props.closeDrawer()})
    }

    const toTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        props.closeDrawer()
    }

    return (<Drawer variant="temporary" anchor="right" open={props.drawerOpen} onClose={props.closeDrawer} style={{
        minWidth: "100vw",
        width: "100vw"
    }} PaperProps={{
        sx: {
            backgroundColor: "black",
            color: "white"
        }
    }}>
        <DrawerHeader>
            <IconButton onClick={animateOut} style={{
                padding: "12px",
                marginLeft: "12px"
            }}>
                <CloseIcon ref={closeIcon} style={{
                    color: "white"
                }} />
            </IconButton>
        </DrawerHeader>
        <div style={{
            width: "100vw"
        }}>
            <div className="logoFootnoteAlt" style={{
                top: "30%",
                width: "160px",
                height: "160px"
            }} onClick={toTop}>
                <svg className="logoFootnoteAlt" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 50" fill="none" xmlSpace="preserve">
                    <g>
                        <g>
                            <path fill="none" stroke="#FFFFFF" d="M24.997,47.511C11.214,47.511,0,36.298,0,22.515C0,12.969,5.314,4.392,13.869,0.132
                 c0.385-0.191,0.848-0.117,1.151,0.186s0.381,0.766,0.192,1.15C13.651,4.64,12.86,8.05,12.86,11.601
                 c0,12.681,10.316,22.997,22.997,22.997c3.59,0,7.033-0.809,10.236-2.403c0.386-0.191,0.848-0.117,1.151,0.186
                 c0.304,0.303,0.381,0.766,0.192,1.15C43.196,42.153,34.597,47.511,24.997,47.511z" />
                        </g>
                    </g>
                </svg>
                <div style={{
                    fontSize: "100px",
                    zIndex: 10,
                    position: "absolute",
                    color: "rgb(255,255,255)",
                    right: 5,
                    top: -40
                }}>
                    𝓡
                </div>
            </div>
            <List style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,0)"
            }}>
                <ButtonClose redirect={true} text="About Me" link="aboutMe" closeDrawer={props.closeDrawer} />
                <Divider />
                <ButtonClose redirect={true} text="Projects" link="projects" closeDrawer={props.closeDrawer} />
                <Divider />
                <ButtonClose redirect={false} text="Contact" link="" closeDrawer={props.combine} />

            </List>
            <div style={{
                position: "absolute",
                bottom: 10,
                left: "50%",
                transform: "translate(-50%,0)"
            }}>
                <Contacts startColor="rgb(0,0,0)" hoverColor="rgb(111,137,230)" />
            </div>
        </div>
        <Divider />
    </Drawer>);
}

function ButtonClose(props) {
    const closeDrawer = () => {
        props.closeDrawer()
    }
    if (!props.redirect) {
        return (
            <ListItem disablePadding={true}>
                <ListItemButton className='hoverButton' disableRipple={true} onClick={closeDrawer} style={{ textAlign: "center" }}>
                    <ListItemText primary={props.text} />
                </ListItemButton>
            </ListItem>
        )
    }
    return (
        <ListItem disablePadding={true}>
            <ListItemButton className='hoverButton' disableRipple={true} href={"#" + props.link} onClick={closeDrawer} style={{ textAlign: "center" }}>
                <ListItemText primary={props.text} />
            </ListItemButton>
        </ListItem>
    )
}

function DrawerHeader(props) {
    return (
        <div style={{ width: "100%", height: "80px", display: "flex", alignItems: "center" }}>
            {props.children}
        </div>
    )
}