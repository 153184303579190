import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useRef, useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import AboutMe from './AboutMe.js';
import './App.css';
import firebase from './firebase-config'
import ContactPopup from './ContactPopup.js';
import CustomAppbar from './CustomAppbar.js';
import Footnote from "./Footnote.js";
import LandingPage from './LandingPage';
import Projects from './Projects.js';
import rightImg from './rightCompressed.webp';
import rightImgTextured from './rightTexturedCompressed.webp';
import { get, ref } from 'firebase/database';



const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          background: "linear-gradient(45deg,rgb(111,137,230),rgb(180,97,185))",
          boxShadow: "inset 0 0 35px 0px rgba(0, 0, 0, 0.25), inset 0 2px 1px 1px rgba(255, 255, 255, 0), 1px 1px 15px 2px rgba(154, 137, 164, 0.5)"
        },
      },
    },
  },
});

theme.typography.h4 = {
  fontSize: '5.2vw',
  '@media (min-width:824px)': {
    fontSize: '2.4rem',
  },
};

theme.typography.h3 = {
  fontSize: "3vw",
  '@media (max-width:824px)': {
    fontSize: "6vw"
  }
}

theme.typography.h1 = {
  fontSize: '13vw',
  '@media (min-width:824px)': {
    fontSize: '6rem',
  },
  '@media (max-width:500px)': {
    fontSize: '4rem',
    wordWrap: "break-word"
  }
};

function App() {

  const container = useRef()
  const rightImgRef = useRef()

  gsap.registerPlugin(ScrollTrigger)

  const [popupOpen, setPopupOpen] = useState(false)
  const [projects, setProjects] = useState([])
  const [numProjects, setNumProjects] = useState(0)



  useEffect(() => {
    const rootRef = ref(firebase)
    get(rootRef).then((snapshot) => {
      const projectsList = snapshot.val()
      setProjects(projectsList);
      setNumProjects(projectsList.length)
    }).catch(error => {
      console.error("Error fetching projects: ", error);
    });

    gsap.to(rightImgRef.current, { opacity: 0, scrollTrigger: { duration: 5, trigger: container.current, start: "200vh", end: "350vh" } })
  },
    [])
  const showSideImg = useMediaQuery(theme.breakpoints.up('lg'))

  const contactPopupChange = (open) => {
    setPopupOpen(open)
  }


  return (
    <ThemeProvider theme={theme} >
      <div style={{ overflowX: "hidden" }}>
        <CustomAppbar showSideImg={showSideImg} contactPopupOpen={contactPopupChange} />
        {showSideImg ?
          <>
            <div style={{ height: "100%", position: "fixed", bottom: 0, right: 0, zIndex: 900 }}>
              <img src={"https://res.cloudinary.com/dzepj4pdl/image/upload/v1719326722/rightTexturedCompressed_qchiu6.webp"} style={{ opacity: 1, height: "100%", position: "relative", zIndex: 900 }} />
            </div>
            <div style={{ height: "100%", position: "fixed", bottom: 0, right: 0, zIndex: 900 }}>
              <img ref={rightImgRef} src={"https://res.cloudinary.com/dzepj4pdl/image/upload/v1719326722/rightCompressed_apbsk8.webp"} style={{ opacity: 1, height: "100%", position: "relative", zIndex: 900 }} />
            </div>

          </>
          : null
        }
        <div className="App" style={{ width: "100vw", maxWidth: "100%", height: "calc(100vh + 1000px)" }}>
          <LandingPage ref={container} isLg={showSideImg} />
          <AboutMe isLarge={showSideImg} />
          <Projects numProjects={numProjects} projects={projects} />
          <Footnote numProjects={numProjects} />
        </div>
      </div>
      <ContactPopup open={popupOpen} closePopup={contactPopupChange} />

      <ToastContainer />

    </ThemeProvider>
  );
}

export default App;
