import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Contacts from './Contacts'
import { useMediaQuery } from '@mui/material'

export default function ContactPopup(props) {

  const closePopup = () => {
    props.closePopup(false)
  }

  const normal = useMediaQuery('(min-width:933px)')
  const smaller = useMediaQuery('(min-width:500px')

  const width = normal ? "50vw" : (smaller ? "75vw" : "100vw")
  return (
    <Dialog open={props.open} onClose={closePopup} PaperProps={{
      style: {
        background: 'linear-gradient(rgb(180, 97, 185),rgb(111,137,230))',
        width: width,
        height: smaller ? "50vh" : "100vh",
        color: "white"
      },
    }}>
      <DialogTitle>
        Contact Information
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ color: "white" }}>
          You can view my work on Devpost, Github, and Kaggle, and can contact me with my email, <a href="mailto:rpinisetti25@nobles.edu" style={{ color: "white" }}>rpinisetti25@nobles.edu</a>. If you need a web developer, I'd love to help build or maintain your website. I look forward to hearing from you!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Contacts />
      </DialogActions>
    </Dialog>
  )
}
