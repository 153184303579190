import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useEffect, useRef, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';
import Contacts from './Contacts.js';
import "./Footnote.css";
import { Tooltip } from '@mui/material';
function Footnote(props) {
    const year = new Date().getFullYear()
    const logo = useRef()
    const r = useRef()
    const container = useRef()
    const email = useRef()


    const theme = useTheme()
    const showSideImg = useMediaQuery(theme.breakpoints.up('md'))

    const [cursor, setCursor] = useState("default")

    useEffect(() => {


    }, [])


    const toTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    const emailHover = () => {
        gsap.to(email.current, { duration: 0.2, borderWidth: "2px" })
    }
    const emailLeave = () => {
        gsap.to(email.current, { duration: 0.2, borderWidth: "0px" })
    }
    console.log( `calc(100vh + ${showSideImg?"980px":"0px"} + ${showSideImg?75*props.numProjects:20*props.numProjects}vh)`)

    return (
        <div className="panel" style={{ width: "100vw", height: "100vh", overflow: 'hidden', backgroundColor: "black", position: "absolute", zIndex: 1000, marginTop: `calc(100vh + ${showSideImg?980:170*props.numProjects}px + ${showSideImg?75*props.numProjects:"0"}vh)` }}>
            <Tooltip title="Back to top">
                <div className="logoFootnote" onClick={toTop} style={{ top: "31%", width: "160px", height: "160px" }}>
                    <svg className="logoFootnote" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 50 50" fill="none" xmlSpace="preserve">
                        <g>
                            <g>
                                <path fill="none" stroke="#FFFFFF" d="M24.997,47.511C11.214,47.511,0,36.298,0,22.515C0,12.969,5.314,4.392,13.869,0.132
     c0.385-0.191,0.848-0.117,1.151,0.186s0.381,0.766,0.192,1.15C13.651,4.64,12.86,8.05,12.86,11.601
     c0,12.681,10.316,22.997,22.997,22.997c3.59,0,7.033-0.809,10.236-2.403c0.386-0.191,0.848-0.117,1.151,0.186
     c0.304,0.303,0.381,0.766,0.192,1.15C43.196,42.153,34.597,47.511,24.997,47.511z"/>
                            </g>
                        </g>
                    </svg>
                    <div ref={r} style={{ fontSize: "100px", zIndex: 10, position: "absolute", color: "white", right: 5, top: -40 }}>
                        𝓡
                    </div>
                </div>
            </Tooltip>
            <div className="footnote">
                <div style={{ bottom: 0, marginTop: "80px" }}>
                    <Typography style={{ marginBottom: "10px" }}>
                        <Link underline="none" onMouseEnter={emailHover} onMouseLeave={emailLeave} ref={email} style={{ color: "rgb(180,97,185)", borderBottom: "1px solid rgb(180,97,185)", borderWidth: "0px", paddingBottom: "5px" }} href="mailto: rpinisetti25@nobles.edu">rpinisetti25@nobles.edu</Link>
                    </Typography>
                    <Contacts startColor="rgb(0,0,0)" hoverColor="rgb(111,137,230)" />
                    <Typography variant="caption" style={{ color: "rgb(82,94,101)" }}>
                        <a href="https://iconscout.com/icons/python" target="_blank">Python Icon</a> by <a href="https://iconscout.com/contributors/icon-mafia" target="_blank">Icon Mafia</a>
                    </Typography>
                    <br />
                    <Typography variant="caption" style={{ color: "rgb(82, 94, 101)" }}>
                        &copy; Rohin Pinisetti {year}
                    </Typography>

                </div>
            </div>
        </div>
    )
}

export default Footnote
