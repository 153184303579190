import { Typography } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import gsap from 'gsap'
import React, { useEffect, useRef, useState } from 'react'
import isTouchScreendevice from './IsTouch'
import "./Start.css"



export default function Start(props) {
    const right = useRef()
    const section = useRef()
    const main = useRef()
    const buttonRef = useRef()
    const blurb = useRef()
    const scroll = props.scroll

    const [shouldShowCursor, setShowCursor] = useState(true)
    const [shouldShowCursor2, setShowCursor2] = useState(true)


    useEffect(() => {
        gsap.to(blurb.current, { duration: 5, color: "rgba(255,255,255,1)", delay: 0 })
    }, [])


    const isTouch = isTouchScreendevice()
    const smaller = useMediaQuery('(max-width:600px)')


    return (
        <div style={{ color: "white", height: "100%", width: "100%" }} ref={main} >
            <div className="section" ref={section}>
                <Typography variant="h4" style={{ color: "rgb(142,132,207)" }} className="text">
                    Hello world! My name is,
                </Typography>
                <Typography variant="h1" className="name" style={{paddingBottom:14,paddingTop:12, color: "rgb(111,137,230)", marginTop: "13px", background:"linear-gradient(rgb(185,101,180), rgb(114,186,216))", webkitTextFillColor: "transparent", webkitBackgroundClip:  "text" }}>
                    Rohin Pinisetti
                </Typography>
                <Typography ref={blurb} variant="p" style={{ color: "rgba(255,255,255,0)" }} className="summary">
                    I'm a senior who loves to code and build interactive websites and apps. I also enjoy learning new machine learning concepts and implementing them.
                </Typography>
            </div>


        </div>
    )
}
