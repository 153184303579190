import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import gsap from 'gsap';
import { useRef, useState } from 'react';
import './App.css';
import Contacts from './Contacts';
import HideOnScroll from './HideOnScroll';
import Sidebar from './Sidebar.js';




export default function CustomAppbar(props) {
    const showSideImg = props.showSideImg

    const aboutMe = useRef()
    const projects = useRef()
    const contacts = useRef()
    const resumeButton = useRef()

    const buttons = [aboutMe,projects,contacts]

    const [drawerOpen, setDrawerOpen] = useState(false)

    const displayContactInfo = () => (
        props.contactPopupOpen(true)
    )

    const closeDrawer = () => {
        setDrawerOpen(false)
    }
    const openDrawer = () => {
        setDrawerOpen(true)

    }

    const combine = () => {
        closeDrawer()
        displayContactInfo()
    }
    const shrinkMenu = useMediaQuery('(max-width:600px)')



    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    const buttonHover = (idx) => {
        gsap.to(buttons[idx].current, { duration: 0.2, borderWidth: "2px" })
    }
    const buttonLeave = (idx) => {
        gsap.to(buttons[idx].current, { duration: 0.2, borderWidth: "0px" })
    }

    const resumeHover = () => {
        gsap.to(resumeButton.current,{duration:0.5,transform:"scale(1.15)",boxShadow:"",borderColor:"rgb(180,97,185)",color:"rgb(180,97,185)"})
    }
    const resumeLeave = () => {
        gsap.to(resumeButton.current,{duration:0.5,transform:"scale(1)",boxShadow:"none",borderColor: "rgb(111, 137, 230)", color: "rgb(111, 137, 230)"})
    }

    return (
        <HideOnScroll>
            <AppBar style={{ background: "transparent", boxShadow: "none" }}>
                <Toolbar disableGutters style={{ minHeight: "80px", justifyContent: "space-between", display: "flex" }}>
                    <div style={{ width: showSideImg ? "calc(100vw - 503px)" : "100vw", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <IconButton onClick={scrollToTop} style={{ width: "54px", height: "54px", marginLeft: "5%" }}>
                            <svg version="1.1" style={{ width: "100%", height: "100%" }} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 50 50" fill="none" xmlSpace="preserve">
                                <g>
                                    <g>
                                        <path fill="none" stroke="#FFFFFF" d="M24.997,47.511C11.214,47.511,0,36.298,0,22.515C0,12.969,5.314,4.392,13.869,0.132
c0.385-0.191,0.848-0.117,1.151,0.186s0.381,0.766,0.192,1.15C13.651,4.64,12.86,8.05,12.86,11.601
c0,12.681,10.316,22.997,22.997,22.997c3.59,0,7.033-0.809,10.236-2.403c0.386-0.191,0.848-0.117,1.151,0.186
c0.304,0.303,0.381,0.766,0.192,1.15C43.196,42.153,34.597,47.511,24.997,47.511z"/>
                                    </g>
                                </g>
                            </svg>
                        </IconButton>
                        {
                            shrinkMenu ?
                                <IconButton onClick={openDrawer} style={{ color: "white" }} size="large">
                                    <MenuIcon />
                                </IconButton>

                                :

                                <div style={{marginRight:"5%"}}>
                                    <Button disableRipple={true} variant="contained" style={{ background: "transparent", boxShadow: "none", textTransform: "none" }}>
                                        <Link underline="none" href="#aboutMe" onMouseEnter={() => buttonHover(0)} onMouseLeave={() => buttonLeave(0)} ref={aboutMe} style={{ color: "white", borderBottom: "1px solid rgb(180,97,185)", borderWidth: "0px", paddingBottom: "5px" }}>About Me</Link>

                                    </Button>
                                    <Button disableRipple={true} variant="contained" style={{ background: "transparent", boxShadow: "none", textTransform: "none" }}>
                                        <Link underline="none" href="#projects" onMouseEnter={() => buttonHover(1)} onMouseLeave={() => buttonLeave(1)} ref={projects} style={{ color: "white", borderBottom: "1px solid rgb(180,97,185)", borderWidth: "0px", paddingBottom: "5px" }}>Projects</Link>
                                    </Button>
                                    <Button onClick={displayContactInfo} disableRipple={true} variant="contained" style={{ background: "transparent", boxShadow: "none", textTransform: "none" }}>
                                        <Link underline="none" onMouseEnter={() => buttonHover(2)} onMouseLeave={() => buttonLeave(2)} ref={contacts} style={{ color: "white", borderBottom: "1px solid rgb(180,97,185)", borderWidth: "0px", paddingBottom: "5px" }}>Contact</Link>

                                    </Button>
                                    <Button ref={resumeButton} onMouseEnter={resumeHover} onMouseLeave={resumeLeave} disableRipple={true} variant="outlined" style={{ background: "black", boxShadow: "none", textTransform: "none", borderColor: "rgb(111, 137, 230)", color: "rgb(111, 137, 230)" }}>
                                        <a style={{ textDecoration: "none", color: "inherit" }} href="https://drive.google.com/file/d/1jzbxGMJ-z52GgcdwZ8Z4IfvE0Nfo6y_B/view?usp=sharing" target="_blank" rel="noreferrer">
                                            Resume
                                        </a>
                                    </Button>
                                </div>

                        }

                        <Sidebar drawerOpen={drawerOpen} closeDrawer={closeDrawer} combine={combine}></Sidebar>
                    </div>
                    <div style={{ width: "416px", display: showSideImg ? "flex" : "none", justifyContent: "center" }}>
                        <Contacts startColor="rgb(0,0,0)" hoverColor="rgb(111,137,230)" />
                    </div>
                </Toolbar>
            </AppBar>
        </HideOnScroll>
    )
}

