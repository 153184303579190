import { GitHub } from '@mui/icons-material';
import EmailIcon from '@mui/icons-material/Email';
import Contact from './Contact';
import "./Contact.css";
import "./Footnote.css";
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export default function Contacts(props) {




    return (
        <div style={{whiteSpace:"nowrap"}}>
            <Contact tooltipTitle="Email" link="mailto:rpinisetti25@nobles.edu">
                <EmailIcon className="contact" style={{ display: "inline-block", color: "white" }} />
            </Contact>
            <Contact tooltipTitle="Devpost" link="https://devpost.com/rpinisetti25?ref_content=user-portfolio&ref_feature=portfolio&ref_medium=global-nav">
                <svg className="contact" style={{ display: "inline-block"}} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 280.3 242" enableBackground="new 0 0 280.3 242" xmlSpace="preserve">
                    <g id="XMLID_1_">
                        <path id="XMLID_6_" fill="white" d="M133.7,76H118v90h14.7c30.9,0,45.1-18.1,45.1-45C177.8,90.9,164.9,76,133.7,76z" />
                        <path id="XMLID_9_" fill="white" d="M210.2,0H70.1L0,121l70.1,121h140.1l70.1-121L210.2,0z M132.7,195H89V47h45.8c42.1,0,73.3,20.1,73.3,74   C208.1,172.8,170.6,195,132.7,195z" />
                    </g>
                </svg>
            </Contact>
            <Contact tooltipTitle="LinkedIn" link="https://www.linkedin.com/in/rohin-pinisetti/">
                <LinkedInIcon className="contact" style={{ display: "inline-block", color: "white" }}/>
            </Contact>
            <Contact tooltipTitle="Github" link="https://github.com/Rohin2022">
                <GitHub className="contact" style={{ display: "inline-block", color: "white" }} />
            </Contact>
            <Contact tooltipTitle="Kaggle" link="https://www.kaggle.com/rohinpinisetti">
                <div style={{ display: "inline-block" }}>
                    <svg className="contact" height="40px" style={{ enableBackground: "new 0 0 512 512", width: "40px !important", height: "40px !important" }} version="1.1" viewBox="0 0 512 512" width="40px" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><g id="_x31_89-kaggle"><g><path style={{ fill: "white" }} d="M385.708,476.478L254.742,313.713l125.578-121.534c2.334-2.426,1.526-9.433-4.761-9.433h-62.16    c-3.145,0-6.288,1.618-9.433,4.761L185.128,307.604V32.738c0-4.491-2.247-6.737-6.738-6.737h-46.618    c-4.492,0-6.737,2.246-6.737,6.737v446.433c0,4.491,2.246,6.738,6.737,6.738h46.618c4.491,0,6.738-2.247,6.738-6.738v-97.91    l27.666-26.317l99.257,126.294c2.695,3.145,5.839,4.762,9.432,4.762h60.095c3.143,0,4.939-0.899,5.389-2.696L385.708,476.478z" /></g></g><g id="Layer_1" /></svg>
                </div>
            </Contact>
        </div>
    )
}
