import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

export default function DescriptionList(props) {
    const data = props.list.split("\n")
    return (
        <List sx={{ listStyleType: 'disc', pl: 2, '& .MuiListItem-root': { display: 'list-item' } }}>
            {data.map((text,idx) => {
                return (
                    <ListItem key={idx} style={{ color: props.color?props.color:"white" }}>{text}</ListItem>
                )
            })}
        </List>
    );
}
